import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../templates/layout'
import LawSolutions from '../components/LawSolutions'
import Tag from '../components/Tag'
import ContactBox from '../components/ContactBox'
import WhatsappIcon from '../images/footer/whatsapp.svg'
import FacebookIcon from '../images/footer/facebook.svg'
import TwitterIcon from '../images/footer/twitter.svg'
import ArticleFeed from '../components/ArticleFeed'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ArticleBox from '../components/ArticleBox'
import '../styles/html-text.scss'

type Props = {
	data: {
		prismicBlog: {
			data: {
				content: {
					html: string
				}
				image: {
					url: string
				}
				meta_description: string
				meta_title: string
				title: {
					text: string
				}
				tags: string
			}
			url: string
		}
		allPrismicBlog: {
			nodes: {
				uid: string
				data: {
					meta_description: string
					meta_title: string
					title: {
						text: string
					}
					content: {
						text: string
					}
					image: {
						url: string
						alt: string
					}
					category: {
						slug: string
					}
				}
			}[]
		}
	}
}

const ArticleDetail = ({ data }: Props) => {
	const article = data.prismicBlog.data
	const articles = data.allPrismicBlog.nodes
	const [tags, setTags] = useState<string[]>([])

	useEffect(() => {
		if (article.tags) {
			setTags(data.prismicBlog.data.tags.split(', '))
		}
	}, [])

	return (
		<Layout border={true}>
			<SEO
				title={article.meta_title}
				description={article.meta_description}
				path={data.prismicBlog.url}
				ogImage={`${article.image.url}w=1200&h=630`}
			/>
			<div className='bg-cream lg:mb-[7.5rem] mb-10 lg:min-h-[386px]'>
				<div className='container font-poppins'>
					<div className='flex relative w-full h-full lg:pt-20 pt-10 lg:pb-16 pb-10'>
						<div className='lg:w-[36%] sm:w-3/5'>
							<div className='mb-4'>
								<Link to='/' className='mr-6 text-small text-grey'>
									Ana Sayfa
								</Link>
								<Link to='/' className='font-medium text-small text-grey'>
									Blog
								</Link>
							</div>
							<h1 className='text-header font-medium lg:mb-[5.125rem] mb-10'>
								{article.title.text}
							</h1>
							<div className='flex items-center'>
								<div className='mr-7 text-small md:leading-10'>Paylaş:</div>
								<a
									href='https://wa.me/905300663333'
									target='_blank'
									title='Whatsapp'
									rel='noreferrer'
								>
									<img src={WhatsappIcon} alt='' className='mr-[25px]' />
								</a>
								<a
									href='https://www.facebook.com/ekhukukarabuluculuk'
									target='_blank'
									title='Facebook'
									rel='noreferrer'
								>
									<img src={FacebookIcon} alt='' className='mr-[25px]' />
								</a>
								<a
									href='https://twitter.com/ekhukuk'
									target='_blank'
									title='Twitter'
									rel='noreferrer'
								>
									<img src={TwitterIcon} alt='' />
								</a>
							</div>
						</div>
						<img
							src={article.image.url}
							alt=''
							className='absolute right-0 top-20 -bottom-10 xl:w-[43rem] w-3/5 lg:!block !hidden aspect-[376/211]'
						/>
					</div>
				</div>
			</div>
			<div className='container mb-6'>
				<div className='lg:w-8/12 mx-auto font-merriweather leading-[34px]'>
					<div
						className='html-text'
						dangerouslySetInnerHTML={{ __html: article.content.html }}
					/>
					<div className='flex flex-wrap'>
						{tags.length > 0 &&
							tags.map((tag, t) => {
								return (
									<Tag
										key={`${tag}-${t.toString()}`}
										text={tag}
										className={`${t === tags.length - 1 ? '' : 'mr-6'}`}
									/>
								)
							})}
					</div>
				</div>
				<LawSolutions className='lg:mt-40' />
				<ArticleFeed
					articleCount={articles.length}
					title='Diğer Makaleler'
					subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
				>
					<div className='md:flex md:gap-10'>
						{articles.map((article, a) => {
							return (
								<ArticleBox
									key={`${article.data.title}-${a.toString()}`}
									link={`/${article.data.category.slug}/${article.uid}`}
									className='basis-1/3'
									header={article.data.title.text}
									imageSrc={article.data.image.url}
									imageAlt={article.data.image.alt}
									text={article.data.content.text}
								/>
							)
						})}
					</div>
				</ArticleFeed>
				<ContactBox />
			</div>
		</Layout>
	)
}

export const query = graphql`
	query Blog($url: String) {
		prismicBlog(url: { eq: $url }) {
			data {
				meta_description
				meta_title
				content {
					html
				}
				image {
					url
				}
				title {
					text
				}
				tags
			}
			url
		}
		allPrismicBlog(limit: 3, sort: { fields: data___date }) {
			nodes {
				uid
				data {
					meta_description
					meta_title
					title {
						text
					}
					content {
						text
					}
					category {
						slug
					}
					image {
						url
					}
				}
			}
		}
	}
`
export default ArticleDetail
