import React from 'react'

type Props = {
	text: string
	className?: string
}

const Tag = ({ text, className }: Props) => {
	return (
		<div
			className={`${
				className ? className : ''
			} bg-cream font-poppins text-small leading-6 py-1 px-4 flex items-center justify-center text-center xl:mb-0 mb-5`}
		>
			{text}
		</div>
	)
}

export default Tag
