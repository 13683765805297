import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/learn-more.scss'
import RightArrow from '../images/home/right-arrow.svg'

type Props = {
	className?: string
}
const LawSolutions = (props: Props) => {
	return (
		<div
			className={`${
				props.className ? props.className : ''
			} mt-20 lg:mb-[12.5rem] md:mb-40 mb-20 relative`}
		>
			<StaticImage
				src='../images/home/law-solutions.svg'
				alt=''
				className='z-[2] md:w-[48%] w-[45%] md:!block !hidden'
			/>
			<div className='bg-cream md:absolute right-0 top-10 md:w-[95%] h-full z-[1]'>
				<div className="md:absolute flex items-center bg-contain bg-[url('../images/home/scales.svg')] md:bg-[length:100%_100%] bg-right-bottom bg-no-repeat h-full lg:w-[55%] md:w-[60%] z-[3] 2xl:right-10 right-0">
					<div className='3xl:ml-[12.25rem] 2xl:ml-[9.75rem] md:ml-[6.25rem] md:p-0 p-6 font-poppins'>
						<h1 className='font-medium text-header mb-4'>
							Hukuk çözümleri için <br />
							iletişime geçin
						</h1>
						<Link
							to='/iletisim'
							className='hover-link text-navyBlue text-small leading-8'
						>
							<div>İletişime Geç</div>
							<div className='arrow-wrapper flex items-center flex-1'>
								<img src={RightArrow} alt='' className='arrow' />
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LawSolutions
